@import 'assets/styles/variables';

* {
  .THEME_DEFAULT {
    --gray: #7c7c7c;
    --gray-bs: #4B4B4B;
    --gray-bs-1: #706B6B;
    --gray-light: #c2c2c2;
    --gray-light-02: #999999;
    --main-gray: #2d2d2d;
    --dark-gray: #333333;
    --dark: #111111;
    --soft-gray: #3E3E3E;
    --white-2: #f0f0f0;
    --white-3: #F5F4FC;
    --main-white: #ffffff;
    --soft-white: #D3D3D3;
    --soft-white-1: #e3e3e3;
    --main-blue: #2198FF;
    --dark-blue: #3B446D;
    --dark-marine: #134367;
    --soft-blue: #138980;
    --soft-blue-1: #1cc5bc;
    --light-blue: #B4D6F4;
    --main-red-1: #BB1A17;
    --main-red: #F44336;
    --dark-red: #8e0000;
    --red-01: #611313;
    --soft-red: #C15353;
    --main-orange: #DE7800;
    --main-green: #087f23;
    --soft-green: #8EC98E;
    --soft-yellow: #cbb75a;

    --input-white-bg: #ffffff;
    --text-color-1: #cbb75a;
    --dark-bg-1: #2d2d2d;
    --button-secondary-color: #7c7c7c;
  }

  .THEME_1 {
    --main-color: #148980;
    --secondary-color: #cbb75a;
    --gray: #7c7c7c;
    --blue-light-01: #38B4DD;
    --button-primary-color: #cbb75a;

  }

  .THEME_2 {
    --main-color: #022846;
    --dark-gray: #022846;
    --main-gray: #14475E;
    --secondary-color: #5CC4BD;
    --gray: #022038;
    --blue-light-01: #022038;

    --dark-bg-1: #022038;
    --white-2: #f7f7f7;

    --button-primary-color: #022846;
  }
}
